import _regeneratorRuntime from 'babel-runtime/regenerator';
import _Promise from 'babel-runtime/core-js/promise';
import _Object$assign from 'babel-runtime/core-js/object/assign';

var _marked = [handlePromiseSaga, formActionSaga].map(_regeneratorRuntime.mark);

import { take, takeEvery, race, put, call, all } from 'redux-saga/effects';

var identity = function identity(i) {
  return i;
};
var PROMISE = '@@redux-form-saga/PROMISE';
var status = ['REQUEST', 'SUCCESS', 'FAILURE'];

function createFormAction(requestAction, types) {
  var payloadCreator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : identity;

  var actionMethods = {};
  var formAction = function formAction(payload) {
    return {
      type: PROMISE,
      payload: payload
    };
  };

  // Allow a type prefix to be passed in
  if (typeof requestAction === 'string') {
    requestAction = status.map(function (s) {
      var a = requestAction + '_' + s;
      var subAction = function subAction(payload) {
        return {
          type: a,
          payload: payloadCreator(payload)
        };
      };

      // translate specific actionType to generic actionType
      actionMethods[s] = a;
      actionMethods[s.toLowerCase()] = subAction;

      return subAction;
    })[0];

    if (types) {
      payloadCreator = types;
    }

    types = [actionMethods.SUCCESS, actionMethods.FAILURE];
  }

  if (types.length !== 2) {
    throw new Error('Must include two action types: [ SUCCESS, FAILURE ]');
  }

  return _Object$assign(function (data, dispatch) {
    return new _Promise(function (resolve, reject) {
      dispatch(formAction({
        request: requestAction(data),
        defer: { resolve: resolve, reject: reject },
        types: types
      }));
    });
  }, actionMethods);
};

function handlePromiseSaga(_ref) {
  var payload = _ref.payload;

  var request, defer, types, resolve, reject, SUCCESS, FAIL, _ref2, winner;

  return _regeneratorRuntime.wrap(function handlePromiseSaga$(_context) {
    while (1) {
      switch (_context.prev = _context.next) {
        case 0:
          request = payload.request, defer = payload.defer, types = payload.types;
          resolve = defer.resolve, reject = defer.reject;
          SUCCESS = types[0], FAIL = types[1];
          _context.next = 5;
          return all([race({
            success: take(SUCCESS),
            fail: take(FAIL)
          }), put(request)]);

        case 5:
          _ref2 = _context.sent;
          winner = _ref2[0];

          if (!winner.success) {
            _context.next = 12;
            break;
          }

          _context.next = 10;
          return call(resolve, winner.success && winner.success.payload ? winner.success.payload : winner.success);

        case 10:
          _context.next = 14;
          break;

        case 12:
          _context.next = 14;
          return call(reject, winner.fail && winner.fail.payload ? winner.fail.payload : winner.fail);

        case 14:
        case 'end':
          return _context.stop();
      }
    }
  }, _marked[0], this);
}

function formActionSaga() {
  return _regeneratorRuntime.wrap(function formActionSaga$(_context2) {
    while (1) {
      switch (_context2.prev = _context2.next) {
        case 0:
          _context2.next = 2;
          return takeEvery(PROMISE, handlePromiseSaga);

        case 2:
        case 'end':
          return _context2.stop();
      }
    }
  }, _marked[1], this);
}

export { PROMISE, createFormAction, formActionSaga, handlePromiseSaga };

export default formActionSaga;